<template>
    <v-dialog v-model="showPlanningDispo.model" fullscreen style="background-color: rgb(144, 164, 174,0.5);">
        <div>
            <iframe style="height: 100vh; width: 100%; visibility:visible;  border: none; overflow: hidden;"
                :src="showPlanningDispo.url"></iframe>
            <v-btn @click="showPlanningDispo.model = false" color="red"
                style="color: white; width: 90vw; position: absolute; bottom: 10px; left:5vw;">
                Fermer
            </v-btn>
        </div>
    </v-dialog>
    <v-dialog v-model="isReplaceMaterielOpenVerif" max-width="300">
        <v-card>
            <v-col class="pb-1" style="font-size: 20px;" align=center>
                Valider ma modification
            </v-col>
            <v-col>
                <v-row justify="center" align="center">
                    <v-col cols="6" align="center">
                        <v-btn @click="function () {
                            isReplaceMaterielOpen = true
                            isReplaceMaterielOpenVerif = false
                        }" color="#F44336" style="color: white;">
                            Non
                        </v-btn>
                    </v-col>
                    <v-col cols="6" align="center">
                        <v-btn @click="changeMatricule" color="#4CAF50" style="color: white;">
                            Oui
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-card>
    </v-dialog>
    <v-dialog v-model="isReplaceMaterielOpen" max-width="500">
        <v-card>
            <v-col class="pb-1" style="font-size: 20px;">
                Sélectionner un nouveau matricule
            </v-col>
            <v-col>
                <v-autocomplete hide-details no-data-text="Aucun matricule trouvé" label="Matricule sélectionné"
                    v-model="selectedMatricule" :items="parcListe" item-value="MATRICULE" item-title="MATRICULE">
                </v-autocomplete>
            </v-col>
            <v-col class="pt-1" align="center">
                <v-btn color="#4CAF50" style="color: white;" @click="function () {
                    isReplaceMaterielOpen = false
                    isReplaceMaterielOpenVerif = true
                }">
                    Valider la modification
                </v-btn>
            </v-col>
        </v-card>
    </v-dialog>
    <v-col v-if="!isLoading" style="max-height: 800px; overflow: auto;">
        <v-col class="px-0">
            <v-card variant="flat" style="border: solid 3px;" :style="{ 'border-color': isEdited ? 'red' : 'green' }">
                <v-chip v-if="isEdited"
                    style="position: fixed; top: 32px; right: 50px; z-index: 2; background-color: rgb(255, 0, 0,0.8); color: white; font-weight: bold; font-size: 10px;">
                    Des modifications ont été apporté pensez à les valider
                </v-chip>
                <v-chip v-if="!isEditable"
                    style="position: fixed; top: 32px; right: 50px; z-index: 2; background-color: rgb(255, 0, 0,0.8); color: white; font-weight: bold; font-size: 10px;">
                    Contrat facturé et plus modifiable
                </v-chip>
                <v-col>
                    <v-row>
                        <v-col cols="auto">
                            <div>Contrat : <span style="font-weight: bold;">{{ localContrat['N° Contrat Comp']
                                    }}</span>
                            </div>
                        </v-col>
                        <v-col cols="auto">
                            <div>Code client : <span style="font-weight: bold;">{{ localContrat['Code client']
                                    }}</span></div>
                        </v-col>
                    </v-row>
                    <v-divider class="my-2"></v-divider>
                    <v-row>
                        <v-col align=center>
                            <table style="background-color: #9E9E9E;width: 100%;">
                                <tr v-for="(elem, idx) in dataToShow" :key="idx"
                                    :style="{ 'background-color': idx % 2 == 0 ? '#FAFAFA' : '#CFD8DC' }">
                                    <td> {{ elem.label }}</td>
                                    <td>
                                        <div v-if="elem.key == 'LocdataS_::Date Debut Loc'">
                                            <input v-if="elem.editable" @change="function () { isEdited = true }"
                                                type="date" v-model="localContrat['LocdataS_::Date Debut Loc']"
                                                style="width: 100%; padding: 15px;border: solid 1px lightgrey;border-radius: 5px;">
                                            <div v-else>
                                                {{ formatDate(localContrat[elem.key], 'DD/MM/YYYY') }}
                                            </div>
                                        </div>
                                        <div v-else-if="elem.key == 'LocdataS_::Date retour'">
                                            {{ formatDate(localContrat[elem.key], 'DD/MM/YYYY') }}
                                        </div>
                                        <div v-else>
                                            <v-select variant="flat" bg-color="white" hide-details
                                                v-if="elem.key == 'Lieu_retrait_nom' && elem.editable"
                                                item-value="PR_NOM_AG"
                                                @update:modelValue="function () { isEdited = true }"
                                                v-model="localContrat.Lieu_retrait_nom" :items="retraitListe"
                                                item-title="PR_NOM_AG"></v-select>
                                            <div v-else>
                                                {{ localContrat[elem.key] }}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col>
                    <v-row>
                        <v-col cols="auto">
                            <v-btn
                                @click="setIframe('/#/etat-des-lieux-materiel?search=' + localContrat['N° Contrat Comp'])"
                                variant="tonal" target="_blank" color="#1A237E">État
                                des lieux</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn @click="setIframe('/#/sav-gestion?search=' + localContrat['N° Contrat Comp'])"
                                color="#1B5E20" variant="tonal" target="_blank">Sav</v-btn>
                        </v-col>
                        <v-col cols="auto">
                            <v-btn @click="setIframe('/#/signature-en-ligne?search=' + localContrat['Code client'])"
                                color="#B71C1C" style="" variant="tonal" target="_blank">Signature des documents</v-btn>
                        </v-col>
                    </v-row>
                    <v-col v-if="options && options.find((val) => val.name == 'paiement_proposition')" class="px-0">
                        <v-card style="border: solid 2px #9E9E9E;border-radius: 0px;" variant="flat" class="pa-2">
                            <v-col cols="12">
                                Paiement effectué par...
                            </v-col>
                            <v-select :readonly="!isEditable" @update:modelValue="function () { isEdited = true }"
                                hide-details variant="outlined" elevation="0"
                                v-model="localContrat.reglement_mode_prevu"
                                :items="[{ title: 'En attente de paiement' }].concat(options.find((val) => val.name == 'paiement_proposition').value)">
                            </v-select>
                        </v-card>
                    </v-col>
                    <v-col v-if="options && options.find((val) => val.name == 'caution_proposition')" class="px-0">
                        <v-card style="border: solid 2px #9E9E9E;border-radius: 0px;" variant="flat" class="pa-2">
                            <v-col cols="12">
                                Caution effectué par...
                            </v-col>
                            <v-select :readonly="!isEditable" @update:modelValue="function () { isEdited = true }"
                                hide-details variant="outlined" v-model="localContrat['caution_multiple::type_caution']"
                                elevation="0"
                                :items="[{ title: 'En attente de caution' }].concat(options.find((val) => val.name == 'caution_proposition').value)">
                            </v-select>
                        </v-card>
                    </v-col>
                    <v-col class="px-0">
                        <v-card style="border: solid 2px #9E9E9E;border-radius: 0px;" variant="flat" class="pa-2">
                            <v-col cols="12">
                                Location(s)
                            </v-col>
                            <v-col v-for="(elem, idx) in matriculListe" :key="idx">
                                <v-card class="pa-2" variant="outlined">
                                    <!-- :items="parcListe.filter((val) => val.SOUS_FAMILLE == elem.CLD_SOUS_FAMILLE)" -->
                                    <v-autocomplete :readonly="!isEditable" class="edition" item-value="MATRICULE"
                                        item-title="MATRICULE" :items="parcListe" hide-details
                                        :label="'Matricule - ' + elem.CLD_SOUS_FAMILLE" v-model="elem.CLD_MATRICULE"
                                        @update:modelValue="matriculeChange(elem)"
                                        no-data-text="Aucun matricule trouvé">
                                    </v-autocomplete>
                                    <v-col v-if="matriculIsFree[elem.CLD_MATRICULE]" class="pb-0" align=center>
                                        <div v-if="matriculIsFree[elem.CLD_MATRICULE].data.disponible == 'false'">
                                            <v-chip color="red" style="font-weight: bold;">
                                                Le {{ elem.CLD_MATRICULE }}
                                                n'est pas disponible
                                            </v-chip>
                                        </div>
                                        <div v-if="matriculIsFree[elem.CLD_MATRICULE].data.disponible == 'true'">
                                            <v-chip color="green" style="font-weight: bold;">
                                                Le
                                                {{ elem.CLD_MATRICULE }}
                                                est disponible
                                            </v-chip>
                                        </div>
                                    </v-col>

                                    <v-col class="px-0 pb-0" align=center>
                                        <v-row>
                                            <v-col>
                                                <v-card variant="flat"
                                                    :style="elem.CLD_STATUT == 'En attente' ? 'color: white;background-color: #1A237E;' : 'border:solid 1px lightgrey'">En
                                                    attente</v-card>
                                            </v-col>
                                            <v-col>
                                                <v-card variant="flat"
                                                    :style="elem.CLD_STATUT == 'Sortie' ? 'color: white;background-color: #1B5E20;' : 'border:solid 1px lightgrey'"
                                                    value="Sortie"> Sortie</v-card>
                                            </v-col>
                                            <v-col>
                                                <v-card variant="flat"
                                                    :style="elem.CLD_STATUT == 'Retour' ? 'color: white;background-color: #B71C1C;' : 'border:solid 1px lightgrey'"
                                                    value="Retour"> Rentrer</v-card>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col class="px-0 pb-1" align=center>
                                        <v-chip style="font-weight: bold;">
                                            {{
                                                getContartDureById[elem.CLD_ID_DUREE_LOCATION]['CAT_Tarif_GM_id::Nom_Grille']
                                            }}
                                        </v-chip>
                                        <!-- {{ elem }} -->
                                        <!-- {{ elem.CLD_FAMILLE }} -->
                                        <!-- {{ elem.CLD_ID_DUREE_LOCATION }} -->
                                    </v-col>
                                </v-card>
                            </v-col>
                        </v-card>
                    </v-col>
                    <v-col align=center v-if="isEditable">
                        <v-btn color="#4CAF50" :disabled="!isEdited" style="color:white" variant="flat"
                            @click="updateContrat">
                            Valider mes modifications
                        </v-btn>
                    </v-col>
                </v-col>
            </v-card>
        </v-col>
        <!-- <v-col class="px-0" v-if="matriculListe && matriculListe.length > 0 && isEditable"> -->
        <v-col class="px-0" v-if="matriculListe && matriculListe.length > 0">
            <v-card style="border: solid 2px #9E9E9E;border-radius: 0px;" variant="flat" class="pa-2">
                <div class="pl-4 pt-3 pb-2">
                    Action par materiel
                </div>
                <v-col>
                    <v-row>
                        <v-col>
                            <v-autocomplete variant="outlined" label="Sélectionner un materiel" class="edition"
                                return-object="" v-model="actionData.matricul" item-title="CLD_MATRICULE"
                                :items="matriculListe" hide-details>
                            </v-autocomplete>
                        </v-col>
                        <v-col>
                            <v-autocomplete variant="outlined" :disabled="actionData.matricul == undefined"
                                label="Sélectionner une action" class="edition" return-object="" hide-details
                                v-model="actionData.function" :items="actionListe">
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col v-if="actionData.matricul != undefined && actionData.function != undefined" align=center>
                    <v-btn variant="flat" style="color: white;background-color: rgb(92, 107, 192);" @click="runAction">
                        {{ actionData.function.title }} - {{ actionData.matricul.CLD_MATRICULE }}
                    </v-btn>
                </v-col>
                <v-col v-else align="center">
                    <v-btn disabled style="color: white;background-color: rgb(92, 107, 192);">
                        choisir un materiel et une action
                    </v-btn>
                </v-col>
            </v-card>
        </v-col>
        <v-col class="px-0">
            <v-card style="border: solid 2px #9E9E9E;border-radius: 0px;" variant="flat" class="pa-2">
                <div class="mt-2 pa-2" align=center>
                    <v-btn color="#4CAF50" style="color:white;width: 100%;" variant="flat" @click="renewContrat">
                        renouveler contrat
                    </v-btn>
                </div>
            </v-card>
        </v-col>
        <v-col class="px-0" v-if="isEditable">
            <v-card style="border: solid 2px #9E9E9E;border-radius: 0px;" variant="flat" class="pa-2">
                <div class="mt-2 pa-2" align=center>
                    <v-btn @click="sendToFacturation" variant="flat" color="rgb(92, 107, 192)"
                        style="color:white;width: 100%;">
                        Facturer
                    </v-btn>
                </div>
            </v-card>
        </v-col>
        <v-col class="px-0" align="right">
            <v-btn color="#D32F2F" style="color:white" variant="flat">
                Clôturer le contrat
            </v-btn>
        </v-col>
    </v-col>
    <v-col v-else align=center>
        <v-progress-circular :size="150" :width="4" color="primary" indeterminate>
            Chargement
        </v-progress-circular>
    </v-col>
</template>

<script>
import moment from 'moment'
import engine from '../core/DataApi'

export default {
    props: {
        seletedContrat: {},
        parcListe: {},
        options: {},
        contrat_informations: {},
        retraitListe: {},
        getContratsListe: { type: Function },
        closeEdition: { type: Function },
    },
    watch: {
        selectedCaution() {
            engine.update_caution(this.localContrat.ID, 'modeDepot', 'montant', function (data) {
                console.log("update_caution", data);
            })

        }
    },
    data() {
        return {
            actionData: {},
            actionListe: [
                { title: 'Sortir le materiel', value: 'sortirMateriel' },
                { title: 'Rentrer le materiel', value: 'rentrerMateriel' },
                { title: 'Annuler la sortie', value: 'attenteMateriel' },
                { title: 'Envoyer vers etat des lieux', value: 'envoyerMatEtadtdl' },
                { title: 'Envoyer pour signature', value: 'envoyerMatSignature' },
                { title: 'Envoyer pour OR/OP', value: 'envoyerMatOr' },
            ],
            isLoading: false,
            isEdited: false,
            matriculListe: undefined,
            savedMatriculListe: undefined,
            localContrat: {},
            selectedCaution: undefined,
            showPlanningDispo: {},
            matriculIsFree: {},
            isReplaceMaterielOpenVerif: false,
            isReplaceMaterielOpen: false,
            editableKey: ['LocdataS_::N° Mat interne'],
            editableData: {},
        }
    },
    methods: {
        setData() {
            this.selectedMatricule = JSON.parse(JSON.stringify(this.seletedContrat['LocdataS_::N° Mat interne']))
            this.localContrat = JSON.parse(JSON.stringify(this.seletedContrat))
            this.localContrat['LocdataS_::Date Debut Loc'] = moment(this.localContrat['LocdataS_::Date Debut Loc']).format('yyyy-MM-DD')
        },
        runAction() {
            let that = this
            that.isLoading = true
            console.log("actionData", this.actionData);
            this[this.actionData.function.value](this.actionData.matricul, function () {
                that.getContratsListe()
                that.isLoading = false
                // that.getContratsListe(function(){
                //     that.setData()
                // })
            })
        },
        testFunction(mat, callback) {
            console.log("testFunction", mat);
            callback()
        },
        sortirMateriel(mat, callback) {
            console.log("sortirMateriel", mat);
            engine.sortie_mat(mat.CLD_MATRICULE_UID, mat.CLD_NUM_CONTRAT, function (data) {
                console.log("sortirMateriel", data);
                callback()
            })
        },
        rentrerMateriel(mat, callback) {
            console.log("rentrerMateriel", mat);
            callback()
        },
        attenteMateriel(mat, callback) {
            console.log("attenteMateriel", mat);
            engine.retour_mat(mat.CLD_MATRICULE_UID, mat.CLD_NUM_CONTRAT, function (data) {
                console.log("rentrerMateriel", data);
                callback()
            })
        },
        envoyerMatEtadtdl(mat, callback) {
            console.log("envoyerMatEtadtdl", mat);
            engine.send_mat_etadl(mat.CLD_MATRICULE_UID, mat.CLD_NUM_CONTRAT, '', function (data) {
                console.log("envoyerMatEtadtdl", data);
                callback()
            })
        },
        envoyerMatSignature(mat, callback) {
            console.log("envoyerMatSignature", mat);
            callback()
        },

        envoyerMatOr(mat, callback) {
            console.log("envoyerMatOr", mat);
            engine.send_mat_or(mat.CLD_MATRICULE, mat.CLD_NUM_CONTRAT, mat.CLD_ID_CONTRAT_LOC_DATA, '', function (data) {
                console.log("envoyerMatOr", data);
                callback()
            })
        },
        sendToFacturation() {
            console.log("sendToFacturation", this.localContrat);
            let that = this

            that.isLoading = true
            // WebApp_creation_facture_reglement
            engine.facturation(this.seletedContrat.ID, this.seletedContrat['N° Contrat Comp'], this.seletedContrat['reglement_mode_prevu'],
                function (data) {
                    console.log("sendToFacturation", data);
                    that.getContratsListe(function () {
                        that.isLoading = false
                    })
                }
            )
        },
        updateContrat() {
            console.log("updateContrat");

            let that = this
            this.isLoading = true
            this.localContrat['LocdataS_::Date Debut Loc'] = moment(this.localContrat['LocdataS_::Date Debut Loc']).format('MM/DD/YYYY')

            for (let i = 0; i < that.savedMatriculListe.length; i++) {
                if (that.savedMatriculListe[i].CLD_MATRICULE != that.matriculListe[i].CLD_MATRICULE) {
                    engine.change_matricule(that.savedMatriculListe[i]['CLD_NUM_CONTRAT'], that.savedMatriculListe[i]['CLD_ID_CONTRAT_LOC_DATA'],
                        that.savedMatriculListe[i].CLD_MATRICULE, that.matriculListe[i].CLD_MATRICULE, function () {
                        }
                    )
                }
            }

            console.log("update local contrat", that.localContrat);

            engine.update_contrat(that.localContrat, function (data) {
                console.log("update_contrat", data);
                // that.closeEdition()
                that.getContratsListe(function () {
                    // that.setData()
                    that.isEdited = false
                    that.isLoading = false
                })
            })
        },
        renewContrat() {
            let that = this

            that.isLoading = true
            engine.renew_contrat(this.localContrat['N° Contrat Comp'], function (data) {
                console.log("renew_contrat", data);
                that.getContratsListe(function () {
                    that.isLoading = false
                })
            })
        },
        setIframe(url) {
            let finalUrl = window.location.href.split('/#/')[0] + url + '&header=hide'

            this.showPlanningDispo = {
                model: true,
                url: finalUrl
            }
        },
        matriculeChange(mat) {
            let that = this
            let uuid = this.parcListe.find((val) => val.MATRICULE == mat.CLD_MATRICULE).MATRICULE_UID

            this.isEdited = true

            engine.is_materiel_free({ UID: uuid }, function (data) {
                that.matriculIsFree[mat.CLD_MATRICULE] = data
            })
        },
        formatDate(date, format) {
            return moment(date).format(format)
        },
        changeMatricule() {
            this.isReplaceMaterielOpenVerif = false
        }
    },
    computed: {
        dataToShow() {
            let data = [
                {
                    label: 'Client',
                    key: 'Societe',
                    type: 'text',
                    editable: false,
                },
                // {
                //     label: 'Type de contrat',
                //     key: 'LocdataS_::ID_Grille_nom',
                //     type: 'text',
                //     editable: true,
                // },
                {
                    label: 'Agence de retrait',
                    key: 'Lieu_retrait_nom',
                    type: 'text',
                    editable: this.isEditable,
                },
                {
                    label: 'Date de sortie',
                    key: 'LocdataS_::Date Debut Loc',
                    type: 'date',
                    format: 'DD/MM/YYYY',
                    editable: this.isEditable,
                },
                {
                    label: 'Date de retour',
                    key: 'LocdataS_::Date retour',
                    type: 'date',
                    format: 'DD/MM/YYYY',
                    editable: false,
                },
                {
                    label: 'Prix location TTC (€)',
                    key: 'Prix Location Total_TTC',
                    type: 'text',
                    editable: false,
                },
                {
                    label: 'Acompte (€)',
                    key: 'Acompte_final',
                    type: 'text',
                    editable: false,
                },
                {
                    label: 'Caution (€)',
                    key: 'caution_multiple::caution_texte',
                    type: 'text',
                    editable: false,
                },
                {
                    label: 'Etat de la caution',
                    key: 'caution_multiple::etat_caution',
                    type: 'text',
                    editable: false
                },
            ]

            return data
        },
        isEditable() {
            if ((this.seletedContrat['N° FACT Comp'] && this.seletedContrat['N° FACT Comp'].length > 0))
                return false
            return true
        },
        getContratSelectedInformations() {
            console.log("getContratSelectedInformations");

            let veloSub = {}

            for (let i = 0; i < this.contrat_informations.length; i++) {
                let key = this.contrat_informations[i].fieldData.Nom_Genre_Machine
                let value = this.contrat_informations[i].portalData.CAT_Tarif_GM_id.map((val) => val)

                console.log("value", value);

                for (let a = 0; a < value.length; a++) {
                    console.log("test", value[a]);

                }

                veloSub[key] = value
            }

            return veloSub
        },
        getContartDureById() {
            let veloSub = {}

            for (let i = 0; i < this.contrat_informations.length; i++) {
                // let key = this.contrat_informations[i].fieldData.Nom_Genre_Machine
                let value = this.contrat_informations[i].portalData.CAT_Tarif_GM_id.map((val) => val)

                console.log("value", value);

                for (let a = 0; a < value.length; a++) {
                    veloSub[value[a]['CAT_Tarif_GM_id::ID_Grille']] = value[a]
                }
            }

            return veloSub
        },

    },
    mounted() {
        let that = this

        this.setData()

        engine.get_contrat_matricul_liste(this.localContrat['N° Contrat Comp'], function (data) {
            console.log("get_contrat_matricul_liste", data);
            if (data.code == 0) {
                that.matriculListe = JSON.parse(JSON.stringify(data.data.data.map((val) => val.fieldData)))
                that.savedMatriculListe = JSON.parse(JSON.stringify(data.data.data.map((val) => val.fieldData)))
                console.log("matriculListe", that.matriculListe);
            }
        })

        console.log("localContrat", this.localContrat);


    }
}
</script>

<style></style>